let locale = ($('html').attr('lang')).trim();
let lang = require(`./lang/common/${locale}.json`);

$(function() {
    if (!APP_DEBUG && $('.js-has-ad')[0]) { // run online and on pages that have ads
        setTimeout(function() { // wait for ad to load if available
            if (!document.getElementById('adDet') || !adsbygoogle.loaded) { // check one of two approaches
                let html = `<div class="toast-container position-fixed top-0 end-0 p-3">
                    <div class="toast fade show bg-success" role="alert" aria-live="assertive" aria-atomic="true">
                            <div class="toast-header">
                                <strong class="me-auto">${lang.ad_blocker_detected}</strong>
                            </div>
                            <div class="toast-body text-light">
                                ${lang.disable_ad_blocker}
                            </div>
                        </div>
                    </div>`;
                $('body').append(html);
            };
        }, 15000);
    };
});
